<template>
  <div class="h-screen flex w-full items-center justify-center" id="page-login">
    <div class="sm:w-full md:w-1/2 lg:w-1/2 xl:w-1/3 xxl:w-1/3 sm:m-0 m-4">
    <img
                  class="logoCustomLogin"
                  src="https://flattlo-app.s3.amazonaws.com/base-stock-images/flattlo.png"
                  alt="conversion"
                />

      <div class="card">
        <div slot="no-body">
          <div class="vx-row items-center justify-center">
            <div class="vx-col-1 sm:w-full lg:w-full md:w-full lg:w-1/2">
              <form>
                <div class="m-8">
                      <vs-alert  color="warning" title='🔐Remplazo de contraseña' active="true" class="mt-1">
                      Hola {{getUser.first_name}}, puedes ingresar tus nuevas credenciales a continuación:
                    </vs-alert>
                    <br/>
                  <vs-input
                    icon="icon icon-lock"
                    icon-pack="feather"
                    label-placeholder="Nueva contraseña"
                    v-model="nPass"
                    v-validate="'required|min:7'"
                    name="contraseña"
                    ref="contraseña"
                    color="warning"
                    class="w-full mb-2"
                    type="password"
                  />
                   <span
                  class="text-warning text-sm"
                  v-show="errors.has('Contraseña')"
                    >{{ errors.first('Contraseña') }}</span>
                   <vs-input
                    icon="icon icon-lock"
                    icon-pack="feather"
                    label-placeholder="Confirmar contraseña"
                    v-model="cPass"
                    v-validate="'required'"
                    name="confirmar contraseña"
                    ref="confirmar contraseña"
                    color="warning"
                    class="w-full mt-8"
                    type="password"
                  />
                  <span
                  class="text-warning text-sm"
                  v-show="errors.has('Confirmar contraseña')"
                    >{{ errors.first('Confirmar contraseña') }}</span>

                  <vs-button
                    class="large btnCustom vs-con-loading__container"
                    ref="loadableButton2"
                    id="button-with-loading2"
                    v-on:keyup.enter="updateUserPassword()"
                    @click="updateUserPassword()"
                    color="warning"
                    :disabled="confirmPass"
                  >Remplazar contraseña</vs-button>
                  <br/>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { auth as authService  } from '../../../services';

export default {
    data(){
        return{
            nPass: '',
            cPass: '',
            getUser:[],
            backgroundLoading: "#fbdc11",
            colorLoading: "#333232"
        }
    },
    computed:{
       confirmPass(){
           if (this.nPass === this.cPass & this.nPass.length >= 6) {
               return false
           } else {
               return true
           }
       },
       getID(){
           return this.$route.params.Id
       },
    },
  methods:{
    async updateUserPassword() {
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-with-loading2",
        scale: 0.45
      })

      try {
        const res = await authService.updatePassword(this.getID, this.cPass);
        if (res) {
          this.$router.push("/login");
          this.$vs.notify({
            title:'👌Actualización correcta',
            text:'Puedes ingresar ahora con tu nueva contraseña.',
            color: "primary"
          });
        } else {
          this.$vs.notify({
            title: "Error",
            text: "No ha sido posible actualizar tu contraseña",
            color: "primary"
          });
        }
      } catch (error) {
        this.$vs.notify({
          title: "Error",
          text: "No ha sido posible actualizar tu contraseña",
          color: "primary"
        });
      }
    },
  }
}

</script>

<style lang="scss">
.box {
    display: block;
    position: absolute;
    margin: 0 auto;
}
[dir] .swiper-pagination-bullet-active {
  background: #fff !important;
}

#page-login {
  .btnCustom {
    width: 100%;
    margin-top: 36px;
    color: #000;
    font-weight: 500;
    border-radius: 22px;
  }
  .logoCustomLogin {
    display: block;
    margin: 0 auto;
    width: 60%;
    margin-top: 4vh;
  }
  .customLink {
    color: #b8b8b8 !important;
    font-size: 16px;
    cursor: pointer;
  }
  .customLink:hover {
    color: #fbdc11 !important;
    animation-delay: 1s;
  }
  .customWidthRestoreLink {
    font-weight: 500;
  }

  .cMargin {
    display: block;
    margin: 0 auto;
    width: 80%;
  }

  .vs-button:disabled {
    opacity: 0.2;
  }
  .vs-input--placeholder {
    color: #c1c1c1;
    font-weight: 600;
    font-size: 11px;
  }
}
#page-login {
    background-color: #1d324c
}
</style>
